import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useHistory } from 'react-router-dom';
import config from '../../config.json';

import "./Header.css";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const history = useHistory();

  const [Language, setLanguage] = useState(localStorage.language);
  const supportedLangs = [
    ['en', 'English'],
    ['fr', 'Français'],
    ['es', 'Español'],
    ['de', 'Deutsch'],
    ['it', 'Italiano'],
    ['pt', 'Português'],
    ['tr', 'Türkçe'],
    ['pl', 'Polski']
  ];

  const { t } = useTranslation();

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    localStorage.language = Language;
    i18next.changeLanguage(Language)

    // Get the current URL path, search parameters, and hash
    const { pathname, search, hash } = window.location;

    // Determine the new language path based on the selected language
    let newLanguagePath = pathname; // Default to the current path

    // Define language codes and their corresponding path segments
    const languageCodes = ['fr', 'en', 'es', 'de', 'tr', 'pl', 'it', 'pt'];

    // Check if the current path contains a language code
    const currentLanguageCode = languageCodes.find(code => pathname.startsWith('/' + code));

    if (currentLanguageCode) {
      // Replace the current language code with the new language code
      newLanguagePath = pathname.replace('/' + currentLanguageCode, '/' + Language);

      // Append the existing search parameters to the new URL path
      newLanguagePath += search + hash;

      window.history.replaceState({}, document.title, newLanguagePath);
    } else {
      let lng = localStorage.getItem("language")
      newLanguagePath += search + hash;

      if (languageCodes.includes(lng)) {
        history.push(`/${lng}${newLanguagePath}`)
      } else {
        history.push(`/en${newLanguagePath}`)
      }
    }
  }, [Language]);

  return (
    <>
    <header>
      <a href={`/${Language}`} className="logo" style={{ textDecoration: 'none' }}>
        <>
        {config.christmass ? (
            <img src="/assets/img/logo-xmass.webp" alt="Logo" style={{ height: '45px' }} />
        ) : (
            <img src="/assets/img/logo.png" alt="Logo" />
        )}
        </>

        <span>
          <span className="part1">OGame</span>
          <span className="part2">Helpers & tools !</span>
        </span>
      </a>
      <>
      {config.christmass && (
      <div id="top_new_year">
          <div class="top_gift_left"></div>
          <div class="top_gift_mid"></div>
          <div class="top_gift_right"></div>
      </div>
      )}
      </>
      <div className="header-menus">
        {/* Add your menus for larger screens here */}
        <a className="header-menu-item" href={`/${Language}`} title={t("TitleConverter")}>
          {t("TitleConverter")}
        </a>
        <a className="header-menu-item" href={`/${Language}/acs-splitter`} title={t("TitleAcsSplitter")}>
          {t("TitleAcsSplitter")}
        </a>
        <a className="header-menu-item" href={`/${Language}/lanx-calc`} title={t("TitleLanxCalc")}>
          {t("TitleLanxCalc")}
        </a>
        <a className="header-menu-item" href={`/${Language}/position-finder`} title={t("TitlePositionFinder")}>
          {t("TitlePositionFinder")}
        </a>
      </div>
      <div className="language-dropdown">
        <select value={Language} onChange={handleLanguageChange}>
          {supportedLangs.map(([langCode, langName]) => (
            <option key={langCode} value={langCode}>
              {langName} ({langCode})
            </option>
          ))}
        </select>
        <span className="selected-language">
          <img src={`/assets/img/flags/${Language}.webp`} alt="" />
        </span>
        <div className="dropdown-content">
          <ul>
            {supportedLangs.map(([langCode, langName]) => (
              <li
                key={langCode}
                className={Language === langCode ? 'active-lang' : ''}
                onClick={() => setLanguage(langCode)}
              >
                <img src={`/assets/img/flags/${langCode}.webp`} alt={langName} />
                <span>{langName}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="Navbar-modalOpen i-menu" onClick={toggleMenu}></div>
      <div className={`Navbar-modalMask ${isMenuOpen ? 'Navbar-modalMaskShow' : ''}`}></div>
      <div className={`Navbar-modal ${isMenuOpen ? 'Navbar-modalShow' : ''}`}>
        <div className="Navbar-modalContent">
          <div className="Navbar-modalHeader"><div className="Navbar-modalClose" onClick={toggleMenu}></div></div>
          <div className="Navbar-modalRow">
            <a className="tn-uni-btn" href={`/${Language}`} title="">
              <span className="tn-uni-ico i-uni6"></span>
              <span className="tn-uni-name">{t("TitleConverter")}</span>
              <span className="tn-uni-ver">ver. 0.5.1</span>
            </a>
            <a className="tn-uni-btn" href={`/${Language}/acs-splitter`} title="">
              <span className="tn-uni-ico i-uni6"></span>
              <span className="tn-uni-name">{t("TitleAcsSplitter")}</span>
              <span className="tn-uni-ver">ver. 0.5.1</span>
            </a>
            <a className="tn-uni-btn" href={`/${Language}/lanx-calc`} title="">
              <span className="tn-uni-ico i-uni6"></span>
              <span className="tn-uni-name">{t("TitleLanxCalc")}</span>
              <span className="tn-uni-ver">ver. 0.5.1</span>
            </a>
            <a className="tn-uni-btn" href={`/${Language}/position-finder`} title="">
              <span className="tn-uni-ico i-uni6"></span>
              <span className="tn-uni-name">{t("TitlePositionFinder")}</span>
              <span className="tn-uni-ver">ver. 0.5.1</span>
            </a>
            <div className="clear"></div>
          </div>
          <div className="Navbar-modalRow Navbar-modalRowIco">

            <select className="lang" onChange={(e) => setLanguage(e.target.value)} defaultValue={Language}>
              {supportedLangs.map(([langCode, langName]) => (
                <option key={langCode} value={langCode}>{langName}</option>
              ))}
            </select>

            <div className="Navbar-modalRowIcoImg i-lang"></div>
          </div>
        </div>
      </div>
    </header>
    </>
  );
}